import Cookies from 'js-cookie';
import settings from '@/settings';
var tokenKey = settings.tokenKey || 'token'; // console.log(settings)
// console.log('Token key is:' + tokenKey)

export function getToken() {
  return Cookies.get(tokenKey);
}
export function setToken(token, expired) {
  if (expired > 0) {
    return Cookies.set(tokenKey, token, {
      expires: new Date(expired * 1000)
    });
  }

  return Cookies.set(tokenKey, token);
}
export function removeToken() {
  return Cookies.remove(tokenKey);
}