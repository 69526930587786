import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export default {
  name: 'MyComponent',
  props: {
    option: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectionChange: {
      type: Function,
      default: function _default() {}
    } // data: { type: Array, default: () => [] },
    // height: { type: [String, Number], default: ' ' },
    // border: { type: [String, Boolean], default: true }

  },
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      this.$emit('selectionChange', val);
    }
  },
  render: function render(h, content) {
    return h('el-table', // 标签名称
    {
      attrs: _objectSpread({
        id: 'MyTable'
      }, this.$props.option),
      on: {
        'selection-change': this.handleSelectionChange
      },
      style: 'width:100%'
    }, [h('template', {
      slot: 'empty'
    }, [h('div', {
      style: 'width:100%'
    }, '暂无数据信息，请添加数据后刷新~')]), this.$slots.default]);
  }
};