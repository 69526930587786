import request from '@/utils/request'; // 证据清单列表
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=-1&apiID=5277&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E4%BA%8C%E6%9C%9F&projectID=82

export function getCertificateList(data) {
  return request({
    url: 'back/attachmentgroup/listall',
    method: 'post',
    data: data
  });
}
export function addCertificate(data) {
  return request({
    url: 'back/attachmentgroup/add',
    method: 'post',
    data: data
  });
}
export function changeCertificate(data) {
  return request({
    url: 'back/attachmentgroup/edit',
    method: 'post',
    data: data
  });
}
export function changeCertificateStatus(data) {
  return request({
    url: 'back/attachmentgroup/updateStatus',
    method: 'post',
    data: data
  });
}
export function delCertificate(data) {
  return request({
    url: 'back/attachmentgroup/delete',
    method: 'post',
    data: data
  });
}
export function getProjectCertificateList(data) {
  return request({
    url: 'back/project_attachment_group/listall',
    method: 'post',
    data: data
  });
}
export function changeProjectCertificateRequire(data) {
  return request({
    url: 'back/project_attachment_group/updateRequire',
    method: 'post',
    data: data
  });
}
export function changeProjectCertificateStatus(data) {
  return request({
    url: 'back/project_attachment_group/updateEnable',
    method: 'post',
    data: data
  });
}
export function materialListall(data) {
  return request({
    url: 'back/project_material/listall',
    method: 'post',
    data: data
  });
}
export function materialCreditorDetail(data) {
  return request({
    url: 'back/project_material/creditor_detail',
    method: 'post',
    data: data
  });
}
export function materialClientDetail(data) {
  return request({
    url: 'back/project_material/client_detail',
    method: 'post',
    data: data
  });
}
export function materialAttachmentDetail(data) {
  return request({
    url: 'back/project_material/attachment_detail',
    method: 'post',
    data: data
  });
}
export function downTask(data) {
  return request({
    url: 'back/project_material_download_task/listall',
    method: 'post',
    data: data
  });
}
export function addTask(data) {
  return request({
    url: 'back/project_material/download',
    method: 'post',
    data: data
  });
}
export function addAll(data) {
  return request({
    url: 'back/project_material/download_all',
    method: 'post',
    data: data
  });
}
export function listallFg(data) {
  return request({
    url: 'back/project_fg/listall',
    method: 'post',
    data: data
  });
}
export function listallField(data) {
  return request({
    url: 'back/project_field/listall',
    method: 'post',
    data: data
  });
}
export function houseTemplate(data) {
  return request({
    url: 'back/project_template/dl_house',
    method: 'post',
    data: data
  });
}
export function listallDe(data) {
  return request({
    url: 'back/housedetail/listall',
    method: 'post',
    data: data
  });
}
export function housedetail(data) {
  return request({
    url: 'back/housedetail/detail',
    method: 'post',
    data: data
  });
}
export function houseExport(data) {
  return request({
    url: 'back/project_export/house',
    method: 'post',
    data: data,
    isFileData: true
  });
}
export function houseDel(data) {
  return request({
    url: 'back/housedetail/delete',
    method: 'post',
    data: data
  });
}
export function houseField(data) {
  return request({
    url: 'back/housedetail/field',
    method: 'post',
    data: data
  });
}
export function listForClaim(data) {
  return request({
    url: 'back/housedetail/listForClaim',
    method: 'post',
    data: data
  });
}
export function houseAssociate(data) {
  return request({
    url: 'back/housedetail/associate',
    method: 'post',
    data: data
  });
}
export function listallMessage(data) {
  return request({
    url: 'back/house_detail_update_message/listall',
    method: 'post',
    data: data
  });
}
export function unreadNum(data) {
  return request({
    url: 'back/house_detail_update_message/unread_num',
    method: 'post',
    data: data
  });
}
export function readNum(data) {
  return request({
    url: 'back/house_detail_update_message/read',
    method: 'post',
    data: data
  });
}
export function diffM(data) {
  return request({
    url: 'back/house_detail_update_message/field_diff',
    method: 'post',
    data: data
  });
}
export function contentH(data) {
  return request({
    url: 'back/project_claim/associateToHouse',
    method: 'post',
    data: data
  });
} // http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=429&grandSonGroupID=1023&apiID=6327&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37
// 附件总览选项下载

export function materialDownload(data) {
  return request({
    url: 'back/project_material/download_option',
    method: 'post',
    data: data
  });
} // http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=1024&apiID=6357&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37
// 下载

export function getDownloadUrl(data) {
  return request({
    url: 'back/project_material_download_task/get_download_url',
    method: 'post',
    data: data
  });
}