var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Footer" }, [
    _c("div", { staticClass: "left" }, [
      _c("div"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "©CopyRight 1989-" +
            _vm._s(new Date().getFullYear()) +
            " All Rights Reserved "
        ),
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
          [_vm._v("闽ICP备15024382号-2")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }