import request from '@/utils/request'; // 获取登陆验证码
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=1553&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function loginAuthCode() {
  return request({
    url: 'back/login/authcode',
    method: 'post',
    data: {}
  });
} // 获取用户权限
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=1555&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function userRights() {
  return request({
    url: 'back/user/rights',
    method: 'post',
    data: {}
  });
} // 获取扫码登录参数
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=1578&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function authlogin() {
  return request({
    url: 'back/login/jslogin',
    method: 'post',
    data: {}
  });
} // 扫码登陆：用Code换取账号信息
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=2216&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function authCode(code) {
  return request({
    url: 'back/login/code',
    method: 'post',
    transmitError: true,
    data: {
      code: code
    }
  });
} // 请求登录
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=1554&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function login(name, password, authcode) {
  return request({
    url: 'back/login',
    method: 'post',
    data: {
      name: name,
      password: password,
      authcode: authcode
    }
  });
} // 修改密码
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=2217&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function chgpwd(old_pwd, new_pwd) {
  return request({
    url: 'back/user/chgpwd',
    method: 'post',
    data: {
      old_pwd: old_pwd,
      new_pwd: new_pwd
    }
  });
} // 退出
// 链接：http://api.dcx.com/#/home/project/inside/api/detail?groupID=420&childGroupID=434&apiID=2255&projectName=%E4%BF%A1%E5%AE%9E%E7%AE%A1%E7%90%86%E4%BA%BA%E7%B3%BB%E7%BB%9F&projectID=37

export function logout() {
  return request({
    url: 'back/login/logout',
    method: 'post',
    data: {}
  });
}