/* Layout */
import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * 静态路由部分
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/Aview',
  name: 'Aview',
  component: function component() {
    return import('@/views/Aview/index');
  }
}, {
  path: '/404',
  component: Layout,
  redirect: '/404/index',
  hidden: true,
  breadcrumb: false,
  children: [{
    path: 'index',
    meta: {
      title: '页面不存在'
    },
    breadcrumb: false,
    component: function component() {
      return import('@/views/errors/404');
    },
    name: 'Page404'
  }]
}, {
  path: '/401',
  component: Layout,
  redirect: '/401/index',
  hidden: true,
  breadcrumb: false,
  children: [{
    path: 'index',
    meta: {
      title: '权限不足'
    },
    breadcrumb: false,
    component: function component() {
      return import('@/views/errors/401');
    },
    name: 'Page401'
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '我的项目',
      icon: 'dashboard'
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];