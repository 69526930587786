//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { diffM } from '@/api/certificate';
export default {
  data: function data() {
    return {
      drawer: false,
      field_list: [],
      info: {},
      list: [{
        key: 'hd_field_value',
        label: '新'
      }, {
        key: 'hd_old_value',
        label: '旧'
      }]
    };
  },
  methods: {
    show: function show(item) {
      var _this = this;

      var data = {
        id: item.id
      };
      diffM(data).then(function (res) {
        _this.field_list = res.data.field_list;
        _this.info = res.data.info;
      });
      this.drawer = true;
    },
    handleClose: function handleClose() {
      this.drawer = false;
    }
  }
};