/* Layout */
import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    rights: ['user.login']       权限控制，具体到API
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

export var asyncRoutes = [{
  path: '/profile',
  component: Layout,
  name: 'account',
  rights: ['all'],
  meta: {
    title: '我的账号'
  },
  redirect: 'noredirect',
  hidden: true,
  children: [{
    path: 'change_pwd',
    rights: ['all'],
    meta: {
      title: '修改密码'
    },
    component: function component() {
      return import('@/views/profile/change_pwd');
    },
    name: 'profile-change-pwd'
  }, {
    path: 'user_data',
    rights: ['all'],
    meta: {
      title: '账号信息'
    },
    component: function component() {
      return import('@/views/profile/user_data');
    },
    name: 'profile-user-data'
  }]
}, {
  path: '/settings',
  component: Layout,
  name: 'settings',
  rights: ['user.listall', 'user.add', 'user.edit', 'user.status_switch', 'user.delete', 'user.unbind', 'role.listall', 'role.add', 'role.edit', 'role.delete', 'right.role_set'],
  meta: {
    title: '帐号与权限',
    icon: 'user'
  },
  redirect: '/settings/userlist',
  children: [{
    path: 'user_list',
    rights: ['user.listall', 'user.add', 'user.edit', 'user.status_switch', 'user.delete', 'user.unbind', 'user.unbind_project'],
    meta: {
      title: '管理人管理'
    },
    name: 'UserList',
    component: function component() {
      return import('@/views/settings/userList');
    }
  }, {
    path: 'role_list',
    rights: ['role.listall', 'role.add', 'role.edit', 'role.delete', 'right.role_set'],
    meta: {
      title: '角色管理'
    },
    name: 'RoleList',
    component: function component() {
      return import('@/views/settings/roleList');
    }
  }, {
    path: 'right_list',
    rights: ['right.listall', 'right.edit'],
    meta: {
      title: '权限管理'
    },
    name: 'RightList',
    component: function component() {
      return import('@/views/settings/rightList');
    }
  }, {
    path: 'role_right',
    rights: ['right.role_detail', 'right.role_set'],
    meta: {
      title: '角色权限'
    },
    name: 'RoleRight',
    component: function component() {
      return import('@/views/settings/roleRight');
    },
    hidden: true
  }, {
    path: 'person_list',
    rights: ['person.listall'],
    meta: {
      title: '用户列表'
    },
    name: 'PersonList',
    component: function component() {
      return import('@/views/settings/personList');
    }
  }]
}, {
  path: '/customize',
  component: Layout,
  name: 'customize',
  rights: ['setting.listall', 'setting.edit'],
  meta: {
    title: '系统参数',
    icon: 'setting'
  },
  redirect: '/customize/setting_list',
  children: [{
    path: 'setting_list',
    rights: ['setting.listall', 'setting.edit'],
    meta: {
      title: '系统参数'
    },
    name: 'SettingList',
    component: function component() {
      return import('@/views/settings/list');
    }
  }]
}, {
  path: '/field',
  component: Layout,
  name: 'field',
  rights: ['fieldgroup.listall', 'fieldgroup.add', 'fieldgroup.edit', 'fieldgroup.delete'],
  meta: {
    title: '自定义字段',
    icon: 'customize'
  },
  redirect: '/field/field_list',
  children: [{
    path: 'field_list',
    rights: ['fieldgroup.listall', 'fieldgroup.add', 'fieldgroup.edit', 'fieldgroup.delete'],
    meta: {
      title: '自定义字段'
    },
    name: 'FieldList',
    component: function component() {
      return import('@/views/field/list');
    }
  }]
}, {
  path: '/ctype',
  component: Layout,
  name: 'ctype',
  rights: ['ctype.listall', 'ctype.add', 'ctype.edit', 'ctype.delete'],
  meta: {
    title: '债权类型',
    icon: 'zhaiquan'
  },
  redirect: '/ctype/list',
  children: [{
    path: 'list',
    rights: ['ctype.listall', 'ctype.add', 'ctype.edit', 'ctype.delete'],
    meta: {
      title: '债权类型'
    },
    name: 'CtypeList',
    component: function component() {
      return import('@/views/ctype/list');
    }
  }]
}, {
  path: '/cproperty',
  component: Layout,
  name: 'cproperty',
  rights: ['subtype.listall', 'subtype.add', 'subtype.edit', 'subtype.delete'],
  meta: {
    title: '债权性质',
    icon: 'zhaiquan'
  },
  redirect: '/cproperty/property_list',
  children: [{
    path: 'property_list',
    rights: ['subtype.listall', 'subtype.add', 'subtype.edit', 'subtype.delete'],
    meta: {
      title: '债权性质'
    },
    name: 'CtypePropertyList',
    component: function component() {
      return import('@/views/cproperty/property_list');
    }
  }]
}, {
  path: '/project',
  component: Layout,
  name: 'project',
  rights: ['project.listall', 'project.add', 'project.edit', 'project.delete', 'project.claim', 'project.finish', 'project.archive', 'project.log'],
  meta: {
    title: '项目管理',
    icon: 'project',
    cache: true
  },
  redirect: '/project/list',
  children: [// {
  //   path: 'houseList',
  //   rights: ['project_material.listall', 'project_material.creditor_detail', 'project_material.client_detail', 'project_material.attachment_detail', 'project_material.download', 'project_material.download_all'],
  //   meta: { title: '附件总览' },
  //   name: 'houseList',
  //   component: () => import('@/views/project/pane/houseList')
  // },
  {
    path: 'houseList',
    rights: ['housedetail.listall', 'housedetail.detail', 'housedetail.delete', 'project_export.house'],
    meta: {
      title: '房产管理',
      rightshow: true
    },
    name: 'houseList',
    component: function component() {
      return import('@/views/project/pane/houseList');
    },
    hidden: true
  }, {
    path: 'list',
    rights: ['project.listall', 'project.add', 'project.edit', 'project.delete', 'project.claim', 'project.finish', 'project.archive', 'project.log', 'project_deleted.listall', 'project_deleted.revert', 'merge_project.add'],
    meta: {
      title: '项目列表'
    },
    name: 'ProjectList',
    component: function component() {
      return import('@/views/project/list');
    }
  }, {
    path: 'member',
    rights: ['project_member.listall', 'project_member.add', 'project_member.delete'],
    meta: {
      title: '项目成员',
      rightshow: true
    },
    name: 'ProjectMember',
    hidden: true
  }, {
    path: 'ctype',
    rights: ['project_ctype.listall', 'project_ctype.delete'],
    meta: {
      title: '债权类型',
      rightshow: true
    },
    name: 'ProjectCtype',
    hidden: true
  }, {
    path: 'fieldgroup',
    rights: ['project_fg.listall', 'project_fg.add', 'project_fg.edit', 'project_fg.delete', 'project_field.listall', 'project_field.add', 'project_field.edit', 'project_field.delete', 'project_template.up_claim'],
    meta: {
      title: '申报字段',
      rightshow: true
    },
    name: 'ProjectFieldGroup',
    hidden: true
  }, {
    path: 'creditor',
    rights: ['project_creditor.listall', 'project_creditor.add', 'project_creditor.edit', 'project_creditor.delete', 'project_creditor.log', 'project_creditor.import', 'project_creditor.notify', 'project_creditor.download_for_court', 'project_creditor.download_for_vote'],
    meta: {
      title: '债权人',
      rightshow: true
    },
    name: 'ProjectCreditor',
    hidden: true
  }, {
    path: 'shipping',
    rights: ['project_shipping.listall', 'project_shipping.add', 'project_shipping.edit', 'project_shipping.delete'],
    meta: {
      title: '物流',
      rightshow: true
    },
    name: 'ProjectShipping',
    hidden: true
  }, {
    path: 'claim',
    rights: ['project_claim.listall', 'project_claim.request', 'project_claim.edit', 'project_claim.submit', 'project_claim.audit', 'project_claim.assign', 'project_claim.confirm', 'project_claim.preview_confirm', 'project_claim.preview_review', 'project_claim.dl_confirm', 'project_claim.issued', 'project_claim.dissent', 'project_claim.review', 'project_claim.dl_review', 'project_claim.review_issued', 'project_claim.lawsuit', 'project_claim.broke', 'project_claim.archive', 'project_claim.log', 'project_claim.status_log', 'project_comment.add', 'project_claim.delete', 'project_export.claim', 'project_claim.withdraw', 'project_claim.restore', 'project_claim.chg_date', 'project_claim.associateToHouse', 'project_claim.audit_attachment', 'project_claim.audit_creditor', 'project_claim.audit_client', 'housedetail.listForClaim', 'project_claim.chg_dissent_switch'],
    meta: {
      title: '申报',
      rightshow: true
    },
    name: 'ProjectClaim',
    hidden: true
  }, {
    path: 'detail',
    rights: ['all'],
    meta: {
      title: '项目详情'
    },
    name: 'ProjectDetail',
    component: function component() {
      return import('@/views/project/project_info');
    },
    hidden: true
  }, {
    path: 'express',
    rights: ['all'],
    meta: {
      title: '物流信息'
    },
    name: 'ProjectExpress',
    component: function component() {
      return import('@/views/project/express');
    },
    hidden: true
  }, {
    path: 'claim_manager',
    rights: ['all'],
    meta: {
      title: '申报管理'
    },
    name: 'ClaimManager',
    component: function component() {
      return import('@/views/claim');
    },
    hidden: true
  }, {
    path: 'report_list',
    rights: ['project_template.dl_report', 'project_template.up_report', 'project_template.report', 'project_template.delete', 'project_export.report'],
    meta: {
      title: '一览表',
      rightshow: true
    },
    name: 'ReportList',
    component: function component() {
      return import('@/views/project/reportList');
    },
    hidden: true
  }, {
    path: 'report_file_manager',
    rights: ['all'],
    meta: {
      title: '一览表字段管理'
    },
    name: 'ReportFileManager',
    component: function component() {
      return import('@/views/project/reportFileManager');
    },
    hidden: true
  }, {
    path: 'review_situation',
    rights: ['all'],
    name: 'ReviewSituation',
    component: function component() {
      return import('@/views/project/reviewSituation/index.vue');
    },
    hidden: true
  }, {
    path: 'dissent',
    rights: ['all'],
    meta: {
      title: '异议列表'
    },
    name: 'DissentList',
    component: function component() {
      return import('@/views/project/dissent/list.vue');
    },
    hidden: true
  }, {
    path: 'person_manager',
    rights: ['all'],
    meta: {
      title: '人员管理'
    },
    name: 'personManager',
    component: function component() {
      return import('@/views/project/person_manager');
    },
    hidden: true
  }, {
    path: 'attachments',
    rights: ['project_material.listall'],
    meta: {
      title: '附件总览',
      rightshow: true
    },
    name: 'ProjectAttachments',
    hidden: true
  }]
}, {
  path: '/declare',
  component: Layout,
  name: 'declare',
  rights: ['all'],
  meta: {
    title: '申报',
    icon: 'project'
  },
  redirect: '/declare/detail',
  hidden: true,
  children: [{
    path: 'detail',
    rights: ['all'],
    meta: {
      title: '申报'
    },
    name: 'declareDetail',
    component: function component() {
      return import('@/views/declare/detail');
    }
  }]
}, {
  path: '/certificate',
  component: Layout,
  name: 'certificate',
  rights: ['attachmentgroup.listall', 'attachmentgroup.add', 'attachmentgroup.edit', 'attachmentgroup.delete'],
  meta: {
    title: '清单设置',
    icon: 'project',
    cache: true
  },
  redirect: '/certificate/list',
  children: [{
    path: 'list',
    rights: ['attachmentgroup.listall', 'attachmentgroup.add', 'attachmentgroup.edit', 'attachmentgroup.delete'],
    meta: {
      title: '清单设置'
    },
    name: 'certificateIndex',
    component: function component() {
      return import('@/views/certificate');
    }
  }]
}, {
  path: '/downTask',
  component: Layout,
  name: 'downTask',
  rights: ['project_material_download_task.listall'],
  meta: {
    title: '下载任务',
    icon: 'project',
    cache: true
  },
  redirect: '/downTask/list',
  children: [{
    path: 'list',
    rights: ['project_material_download_task.listall'],
    meta: {
      title: '下载任务'
    },
    name: 'downTaskIndex',
    component: function component() {
      return import('@/views/DownTask');
    }
  }]
}, {
  path: '/mergeProject',
  component: Layout,
  name: 'mergeProject',
  rights: ['merge_project.add'],
  meta: {
    title: '合并项目',
    icon: 'project',
    cache: true
  },
  redirect: '/mergeProject/project',
  hidden: true,
  children: [{
    path: 'project',
    rights: ['merge_project.add'],
    meta: {
      title: '合并项目'
    },
    name: 'mergeProjectIndex',
    component: function component() {
      return import('@/views/mergeProject');
    }
  }]
}];