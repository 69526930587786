import { Message } from 'element-ui';
export function ApiFailResponseMessage(result) {
  if (result && result.code !== 1) {
    var detail = result.error || result.message || '未知错误';
    var code = result.code;
    Message({
      message: code ? '错误（' + code + '）：' + detail : '错误：' + detail,
      dangerouslyUseHTMLString: true,
      type: 'error',
      duration: 5 * 1000
    });
  }
}