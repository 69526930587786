var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      on: { mouseenter: _vm.enterMenu, mouseleave: _vm.leaveMenu },
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-part" },
        [
          _c(
            "el-scrollbar",
            { attrs: { "wrap-class": "scrollbar-wrapper" } },
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.activeMenu,
                    collapse: _vm.isCollapse,
                    "background-color": _vm.variables.menuBg,
                    "text-color": _vm.variables.menuText,
                    "unique-opened": false,
                    "active-text-color": _vm.variables.menuActiveText,
                    "collapse-transition": false,
                    mode: "vertical",
                  },
                },
                _vm._l(_vm.permission_routes, function (route) {
                  return _c("sidebar-item", {
                    key: route.path,
                    attrs: { item: route, "base-path": route.path },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "version" }, [_vm._v(_vm._s(_vm.version))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }