import axios from 'axios'; // eslint-disable-next-line

import { Message, MessageBox } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import Qs from 'qs'; // Token失效，需要重新获取token的StatusCode状态码

var tokenFailStatusCodes = [21 // token无效
]; // 创建axios实例

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api的base_url
  timeout: 60000,
  // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}); // request拦截器

service.interceptors.request.use(function (config) {
  // console.log(process.env.VUE_APP_TOKEN)
  if (!config.data) {
    config.data = {
      'token': getToken()
    };
  } else if (config.isFormData || config.isFileData) {
    config.data.append('token', getToken());
  } else {
    config.data['token'] = getToken();
  }

  if (store && store.getters.token && process.env.VUE_APP_TOKEN) {
    config.headers['X-Token'] = getToken();
  }

  if (config.method === 'post' && !config.isFormData && !config.isFileData && !config.isJsonData) {
    config.data = Qs.stringify(config.data);
  }

  if (config.isFileData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // respone拦截器

service.interceptors.response.use(function (response) {
  var code = response.data.ret;

  if (code === 1) {
    // 成功处理
    return response.data;
  } else if (tokenFailStatusCodes.indexOf(code) !== -1) {
    // 表示token为失效状态，需要重新登录
    // const message = response.data.message || '账号状态异常'
    // MessageBox({
    //   title: '登录失效',
    //   message: message + '，需要重新登录',
    //   type: 'error',
    //   confirmButtonText: '重新登录',
    //   customClass: 'zZindex'
    // }).then(() => {
    //   // store.dispatch('fedLogOut').then(() => {
    //   //   router.push({ path: '/login', replace: true })
    //   // })
    //   this.$loginBox.showLoginBox()
    // })
    // 返回null表示不需要catch之后处理
    return Promise.reject(null);
  } else if (response.config.transmitError !== true) {
    Message({
      message: '错误：' + (response.data.err_msg || '未知错误'),
      type: 'error',
      duration: 5 * 1000
    }); // 返回null表示不需要catch之后处理

    return Promise.reject(response.data);
  } else {
    // 失败处理
    // handleApiFailMessage(parseInt(response.data.code), response.data.error || response.data.message)
    return Promise.reject(response.data);
  }
}, function (error) {
  Message({
    message: '网络请求失败：' + error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;