//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      version: process.env.VUE_APP_VERSION,
      isHome: true
    };
  }
};