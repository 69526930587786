import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    page: {
      type: [Number, String],
      default: 0
    },
    limit: {
      type: [Number, String],
      default: 10
    },
    total: {
      type: [Number, String],
      default: 0
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [10, 30, 50, 100];
      }
    }
  },
  methods: {
    handleCurrentChange: function handleCurrentChange(page) {
      this.$emit('change', page);
    },
    handleSizeChange: function handleSizeChange(limit) {
      this.$emit('sizeChange', limit);
    }
  }
};