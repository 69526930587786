import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import HouseMessage from '@/views/project/pane/houseMessage.vue';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    HouseMessage: HouseMessage
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'avatar', 'device', 'name', 'user', 'message'])),
  mounted: function mounted() {
    this.$store.dispatch('task/init');
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    logout: function logout() {
      var _this = this;

      this.$store.dispatch('logOut').then(function () {
        // 需要重新实例化vue-router对象 避免bug
        _this.$router.push('/login');
      });
    },
    toDown: function toDown() {
      this.$router.push({
        path: '/downTask'
      });
    },
    showMessage: function showMessage() {
      this.$store.dispatch('task/clear');
      this.$refs.houseMessage.show();
    }
  }
};