var state = {
  searchParams: {}
};
var mutations = {
  SET_PARAMS: function SET_PARAMS(state, _ref) {
    var type = _ref.type,
        data = _ref.data;
    console.log('type, data', {
      type: type,
      data: data
    });
    state.searchParams[type] = data || {};
  }
};
var actions = {
  setSearchParams: function setSearchParams(_ref2, _ref3) {
    var commit = _ref2.commit;
    var type = _ref3.type,
        data = _ref3.data;
    commit('SET_PARAMS', {
      type: type,
      data: data
    });
  },
  getSearchParams: function getSearchParams(_ref4, _ref5) {
    var commit = _ref4.commit,
        state = _ref4.state;
    var type = _ref5.type;
    return new Promise(function (resolve) {
      resolve(state.searchParams[type] || {});
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};