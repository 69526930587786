var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: { box: true, fh: _vm.isFold } }, [
      _c(
        "div",
        {
          on: {
            click: function () {
              return _vm.headerFold && _vm.foldBtn()
            },
          },
        },
        [_vm._t("header")],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.isFold
      ? _c(
          "div",
          { class: { box: true, ff: _vm.isFold } },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }