var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _vm._t("default", [_c("div")]),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          background: true,
          "current-page": _vm.page,
          "page-size": _vm.limit,
          total: _vm.total,
          "page-sizes": _vm.pageSizes,
          layout: "sizes, total, prev, pager, next, jumper",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }