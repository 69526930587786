import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  user: function user(state) {
    return state.user.info;
  },
  name: function name(state) {
    return state.user.name;
  },
  rights: function rights(state) {
    return state.user.rights;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  enumMap: function enumMap(state) {
    return state.comment.enum_map;
  },
  message: function message(state) {
    return state.task.message;
  }
};
export default getters;