import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listallMessage } from '@/api/certificate';
import { minix } from '@/mixin/page';
import HouseD from "./houseD";
var iniForm = {
  page: 1,
  limit: 10
};
export default {
  components: {
    HouseD: HouseD
  },
  mixins: [minix(iniForm)],
  data: function data() {
    return {
      form: {},
      pages: {},
      visible: false,
      loading: false,
      pvisible: {},
      group_list: [],
      group_loading: false,
      tableData: [],
      options: {},
      search_field: {},
      table_headers: {}
    };
  },
  methods: {
    show: function show() {
      this.clear();
      this.visible = true;
    },
    getList: function getList(page) {
      var _this = this;

      page && (this.form.page = page);
      this.loading = true;
      listallMessage(this.form).then(function (res) {
        _this.tableData = res.data.rows;
        _this.pages.total = res.data.total_num;
        _this.search_field = res.data.options.searchData || {};
        _this.table_headers = res.data.options.headData || {};
        var obj = {};

        for (var key in _this.search_field) {
          obj[key] = '';
        }

        _this.form = _objectSpread(_objectSpread({}, obj), _this.form);
        _this.options = res.data.options;
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    lookD: function lookD(item) {
      this.$refs.houseD.show(item);
    },
    clear: function clear() {
      var obj = {};

      for (var key in this.search_field) {
        obj[key] = '';
      }

      this.form = _objectSpread(_objectSpread({}, this.form), obj);
      this.getList(1);
    },
    close: function close() {
      this.visible = false;
    }
  }
};