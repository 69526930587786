import "core-js/modules/es6.regexp.to-string";
import Vue from 'vue';
import { parseTime } from '@/utils';
/**
 * 时间戳转换为日期
 */

Vue.filter('filterParseTime', function (value, format) {
  if (!value || value === '0') {
    return '';
  }

  return parseTime(value, format);
});
Vue.filter('filterDateTime', function (value, format) {
  if (!value) {
    return '';
  }

  var date = new Date(value);
  var time = date.getTime();
  return time > 0 ? parseTime(time, format) : value;
});
/**
 * 字符串转数字，select的时候可能要用
 */

Vue.filter('toInt', parseInt);
/**
 * 转换成数字，select的时候可能要用
 */

Vue.filter('toString', function (value) {
  return value.toString();
});