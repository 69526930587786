var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: "static/avatar/Account.png" },
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { "line-height": "40px" } }, [
                  _vm._v(_vm._s(_vm.user ? _vm.user.realname : "")),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "inlineBlock",
                      attrs: { to: "/profile/change_pwd" },
                    },
                    [
                      _c("el-dropdown-item", [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("修改密码"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "inlineBlock",
                      attrs: { to: "/profile/user_data" },
                    },
                    [
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("账号信息"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "clicked",
                        attrs: { type: "text" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出登录")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-badge",
            {
              staticClass: "mr-20",
              attrs: { value: _vm.message, size: "mini" },
            },
            [
              _c("el-button", {
                attrs: { icon: "el-icon-message-solid", size: "mini" },
                on: { click: _vm.showMessage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("HouseMessage", { ref: "houseMessage" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }