import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import { optionsAll } from '@/api/common';
var commmentEnum = {
  state: {
    enum_map: {}
  },
  mutations: {
    SET_ENUM: function SET_ENUM(state, enum_map) {
      state.enum_map = enum_map || {};
    }
  },
  actions: {
    getEnumMap: function getEnumMap(_ref) {
      var commit = _ref.commit,
          state = _ref.state;
      return new Promise(function (resolve, reject) {
        if (!Object.keys(state.enum_map).length) {
          optionsAll().then(function (res) {
            commit('SET_ENUM', res.data);
            resolve(res.data);
          }).catch(function (error) {
            reject(error);
          });
        } else {
          resolve(state.enum_map);
        }
      });
    }
  }
};
export default commmentEnum;