var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "",
        visible: _vm.drawer,
        "before-close": _vm.handleClose,
        size: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "f page" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item.key, staticClass: "b" },
            [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
              _vm._v(" "),
              _vm._l(_vm.field_list, function (pfg, index) {
                return _c(
                  "div",
                  { key: pfg.pfg_id, staticClass: "content" },
                  [
                    _c("div", { staticClass: "la" }, [
                      _vm._v(_vm._s(index + 1) + "." + _vm._s(pfg.pfg_name)),
                    ]),
                    _vm._v(" "),
                    _vm._l(pfg.fields, function (pf) {
                      return _c("div", { key: pf.pf_id }, [
                        _c("span", [_vm._v(_vm._s(pf.pf_name) + ":")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: {
                              deleteBtn:
                                _vm.info[pf.pf_id] &&
                                _vm.info[pf.pf_id]["hd_field_value"] !=
                                  _vm.info[pf.pf_id]["hd_old_value"],
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                (_vm.info[pf.pf_id] &&
                                  _vm.info[pf.pf_id][item.key]) ||
                                  "-"
                              )
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }