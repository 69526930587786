import "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import '@/styles/element-variables.scss';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import formCreate from '@form-create/element-ui';
import '@/styles/all.scss';
import '@/styles/index.scss'; // global css

import '@/assets/iconfont/iconfont.css';
import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon

import '@/permission'; // permission control

import '@/utils/filter'; // 对Vue定义常用公共过滤器

import _ from 'lodash';
import Pagination from '@/components/pagination';
import Table from '@/components/Table';
import FoldBox from '@/components/FoldBox';
import bring from '@/utils/bring.js';

var moment = require('moment');

require('moment/locale/ja');

moment.locale('zh-cn'); // lodash的库，做数据过滤非常好用

Vue.prototype.$lodash = _;
Vue.prototype.$moment = moment; // import '@/styles/element/index.css'
// set ElementUI lang

Vue.use(ElementUI, {
  locale: locale
});
Vue.use(formCreate);
Vue.component('Pagination', Pagination);
Vue.component('Table', Table);
Vue.component('FoldBox', FoldBox);
Vue.directive('bring', bring);
Vue.config.productionTip = false; // API错误消息Message方式处理

import { ApiFailResponseMessage } from '@/utils/message';
Vue.prototype.$showApiFailMessage = ApiFailResponseMessage;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});