module.exports = {
  title: '信实管理人工作系统',

  /**
   * API使用的token在本地存储使用的键值，建议每个系统token值不同
   */
  tokenKey: 'xinshi_glht_token',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {number}
   * @description 默认每页显示条目数量
   */
  PAGE_LIMIT: 10,
  newListQuery: function newListQuery(params, data) {
    return Object.assign({
      page: 1,
      limit: this.PAGE_LIMIT,
      total: 0
    }, params, data);
  }
};