//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AppMain, FooterComponent, Navbar, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: 'Layout',
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain,
    TagsView: TagsView,
    FooterComponent: FooterComponent
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    needTagsView: function needTagsView() {
      return this.$store.state.settings.tagsView;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened // withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === 'mobile'

      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};