// eslint-disable-next-line
// import { login, logout, getInfo, fetchToken } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth';
import { login as _login, logout, userRights, authCode } from '@/api/login';
import { resetRouter } from '@/router';
var user = {
  state: {
    token: getToken(),
    name: '',
    rights: null,
    info: null,
    school: null,
    subjects: null
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_RIGHTS: function SET_RIGHTS(state, rights) {
      state.rights = rights;
    },
    SET_INFO: function SET_INFO(state, info) {
      state.info = info;
    }
  },
  actions: {
    // 登录
    login: function login(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username.trim();
      return new Promise(function (resolve, reject) {
        _login(username, userInfo.password, userInfo.code).then(function (response) {
          var data = response.data;
          setToken(data.token, data.expired);
          commit('SET_INFO', data.userinfo);
          commit('SET_TOKEN', data.token);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    wxAuthCode: function wxAuthCode(_ref2, code) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        authCode(code).then(function (res) {
          var userinfo = res.data.userinfo;

          if (userinfo && userinfo.telephone !== '') {
            var data = res.data;
            setToken(data.token, data.token_expired);
            commit('SET_INFO', data.userinfo);
            commit('SET_TOKEN', data.token);
          }

          resolve(res);
        }).catch(function (error) {
          removeToken();
          reject(error);
        });
      });
    },
    // 获取用户信息、权限
    getUserInfo: function getUserInfo(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        userRights().then(function (response) {
          var data = response.data;
          commit('SET_RIGHTS', data.rights);
          commit('SET_INFO', data.userinfo);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    logOut: function logOut(_ref4) {
      var commit = _ref4.commit,
          state = _ref4.state;
      return new Promise(function (resolve, reject) {
        logout(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_RIGHTS', null);
          commit('SET_INFO', null);
          removeToken();
          resetRouter();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    fedLogOut: function fedLogOut(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        commit('SET_RIGHTS', null);
        commit('SET_INFO', null);
        removeToken();
        resetRouter();
        resolve();
      });
    }
  }
};
export default user;