import _defineProperty from "/var/jenkins_home/workspace/XS-creditor_glht_prod/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
export default {
  bind: function bind(el, binding, vnode) {
    el.className = 'copyDom';
    var d = document.createElement('span');
    d.innerHTML = '<div>引入<i class="el-icon-right"></i></div>';
    d.className = 'text';
    d.addEventListener('click', function () {
      var b = binding.value;
      binding.value.fn(_defineProperty({}, b.k, b.v));
    });
    el.append(d);
  },
  inserted: function inserted() {},
  unbind: function unbind() {}
};