//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    headerFold: {
      type: Boolean,
      default: true
    } // foldInfo: {
    //   type: Array,
    //   default: () => (['展开', '收起'])
    // }

  },
  data: function data() {
    return {
      isFold: false
    };
  },
  watch: {
    value: {
      handler: function handler(newV) {
        this.isFold = newV;
      },
      immediate: true
    }
  },
  methods: {
    foldBtn: function foldBtn() {
      this.isFold = !this.isFold;
      this.$emit('update', this.isFold);
      this.$emit('change', this.isFold);
    }
  }
};